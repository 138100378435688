
import '../App.css';
import { Link } from 'react-router-dom'

function Nav({ Tab }) {
    function toggleMenu() {
        const menu = document.getElementById("mobileNavContainer");
        const menuicon = document.getElementById("menuIcon");
        menuicon.classList.toggle("close-button");
        menu.classList.toggle("open-menu");
    }
    return (
        <>
            <div className='mobile-nav' id="mobileNavContainer">
                <div className="mobile-nav-inner">
                    <a href="https://mint.thecrabsclubnft.com" target="_blank" rel="noreferrer">Mint</a>
                    <div className="divider"></div>
                    <a href="#project" onClick={() => toggleMenu()}>Project</a>
                    <div className="divider"></div>
                    <a href="#prologue" onClick={() => toggleMenu()}>Prologue</a>
                    <div className="divider"></div>
                    <a href="#roadmap" onClick={() => toggleMenu()}>Roadmap</a>
                    <div className="divider"></div>
                    <a href="#team" onClick={() => toggleMenu()}>About Us</a>
                    <div className="divider"></div>
                    <a href="#faq" onClick={() => toggleMenu()}>FAQ</a>
                    <div className="divider"></div>
                    <div className='social-icons'>
                        <a href="https://twitter.com/TheCrabsClub" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" /></a>
                        <a href="https://discord.gg/nmeJ5NnVj2" target="_blank" rel="noreferrer"><img src="/assets/discord.svg" alt="" /></a>
                        <a href="https://etherscan.io/address/0x56C99cdcf5dBF9Ced34dfdC036e3803528C2e125" target="_blank" rel="noreferrer"><img src="/assets/etherscan.svg" alt="" /></a>
                    </div>




                </div>


            </div>
            <div className="menu-button" id="menuIcon" onClick={() => toggleMenu()}>
                <div className="line-top"></div>
                <div className="line-bottom"></div>
            </div>
            <nav>
                <div className='flexaudi'>
                    <a className='mainlogowrap' href="#home"><img src="/assets/logo.png" className='mainlogo' alt="" /></a>
                </div>
                <div className='links hide-800'>
                    <a href="https://mint.thecrabsclubnft.com" target="_blank" rel="noreferrer">Mint</a>
                    <a href="#project">Project</a>
                    <a href="#prologue">Mission</a>
                    <a href="#roadmap">Roadmap</a>
                    <a href="#team">About Us</a>
                    <a href="#faq">FAQ</a>
                    <div className='hide-800 social-icons'>
                        <a href="https://twitter.com/TheCrabsClub" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" /></a>
                        <a href="https://discord.gg/nmeJ5NnVj2" target="_blank" rel="noreferrer"><img src="/assets/discord.svg" alt="" /></a>
                        <a href="https://etherscan.io/address/0x56C99cdcf5dBF9Ced34dfdC036e3803528C2e125" target="_blank" rel="noreferrer"><img src="/assets/etherscan.svg" alt="" /></a>
                        {/**<Link className={Tab == "home" ? "link hide-800 linkactive" : "link hide-800"} to="/" >Home</Link> */}
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Nav;
