import Nav from "../components/Nav";
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'

function Home() {

    const currentTab = "home";
    return (
        <>
            <div className="home">
                <Nav Tab={currentTab} />

                <div className="home-top" id="home">

                    <h1>
                        <div className="letter">T</div>
                        <div className="letter">H</div>
                        <div className="letter">E</div>
                        <div className="letter">&nbsp;</div>
                        <div className="letter">C</div>
                        <div className="letter">R</div>
                        <div className="letter">A</div>
                        <div className="letter">B</div>
                        <div className="letter">S</div>


                    </h1>
                    <a href="https://mint.thecrabsclubnft.com" target="_blank" rel="noreferrer">
                        <div className="label smallermobi">
                            <h3>MINT</h3>
                        </div></a>

                </div>
                <div className="project" id="project">
                    <img src="/assets/project.gif" alt="" />
                    <div className="text-part">
                        <div className="title">
                            <h3>Welcome to the</h3>
                            <h2>World of Crabs</h2>
                        </div>
                        <p>
                            With a collection of 10.000 programmatically generated NFTs on the Ethereum blockchain, World of Crabs is a fun and exciting addition to the NFT cosmos that is likely to please both collectors and crab fans. Every crab is unique and possesses a different set of special qualities and attributes.
                            <br /><br />
                            Join us on a journey through a world filled with varied and fascinating crab species. Crabs from around the world left their homes in the sea and come up to the surface of every coast to meet the humans and make huge friendships giving them access to exclusive drops, experiences, and more.
                        </p>
                    </div>
                </div>
                <div className="prologue-wrap" id="prologue">
                    <div className="prologue">
                        <div className="text-part">
                            <div className="title">
                                <h2>There are no limits in the ocean and on land.</h2>
                            </div>

                            <p>
                                Our mission is simple and always be the same. Making a friendship with a crab will allow us to invest more, specifically in our community. Take the opportunity of this ground-breaking initiative and opportunity to connect with like-minded people and discover new perspectives.
                                <br /><br />
                                The Crabs are using an ERC721A contract. This will enable minting multiple NFTs for essentially, the same cost as minting a single NFT.
                                <br /><br />
                                Whether you're an experienced NFT collector or a newcomer to the space, World of Crabs is a project that you won't want to miss. Discover with us the wonders of this captivating world of crustaceans.
                                <br /><br />
                                Join the Crab Club and become part of our epic community !

                            </p>
                        </div>
                        <img src="/assets/prologue.png" alt="" />

                    </div>
                </div>

                <div class="timeline" id="roadmap">
                    <div className="text-part">
                        <div className="title">
                            <h2>ROADMAP</h2>
                        </div>
                    </div>
                    <div class="timeline__event  animated  timeline__event--type1">
                        <div class="timeline__event__icon ">
                            <img src="/assets/roadmap1.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            1
                        </div>
                        <div class="timeline__event__content ">
                            <div class="timeline__event__title">
                                <div className="labels">
                                    <span>Phase</span>
                                    <div className="info-label"><em>crab one</em></div>
                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    10.000 unique artworks created <br />
                                    Launch website<br />
                                    Launch Social Media<br />
                                    Community engagement<br />
                                    Whitelist mint<br />
                                    Public sale mint<br />
                                    Full reveal<br />
                                    Ready for secondary market sales
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="timeline__event animated timeline__event--type2">
                        <div class="timeline__event__icon">
                            <img src="/assets/roadmap2.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            2
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">

                                <div className="labels">
                                    <span>Phase</span>
                                    <div className="info-label"><em>crab two</em></div>
                                </div>
                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    Updated website new functionality <br />
                                    Crab store<br />
                                    Community rewards<br />
                                    Votes on Charitable fund projects
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="timeline__event animated  timeline__event--type3">
                        <div class="timeline__event__icon">
                            <img src="/assets/roadmap3.png" alt="" />

                        </div>
                        <div class="timeline__event__date">
                            3
                        </div>
                        <div class="timeline__event__content">
                            <div class="timeline__event__title">
                                <div className="labels">
                                    <span>Phase</span>
                                    <div className="info-label"><em>crab three</em></div>
                                </div>

                            </div>
                            <div class="timeline__event__description">
                                <p>
                                    Crab Club members' exclusive airdrops <br />
                                    Events for the community<br />
                                    Marketplace opens<br />
                                    New collaborations<br />
                                    New plans revealed
                                </p>

                            </div>

                        </div>
                    </div>

                </div>
                <div className="team-wrap" id="team">
                    <div className="team">

                        <div className="title">
                            <h2>ABOUT <span>US</span></h2>
                        </div>
                        <div className="title">
                            <p>We are a team of 3 friends who entered the web3 to try our
                                skills and building something for the future so the world will love it!</p>
                        </div>
                        <div className="grid">
                            <div className="card">
                                <img src="/assets/dennis.png" alt="" />
                                <h3>Dennis</h3>
                                <p>
                                    Artist and the Founder of the project who learned everything on his own.
                                </p>
                            </div>
                            <div className="card">
                                <img src="/assets/stella.png" alt="" />
                                <h3>Stella </h3>
                                <p>
                                    Social Media Management who loves perfection.
                                </p>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="card">
                                <img src="/assets/bruno.png" alt="" />
                                <h3>Bruno</h3>
                                <p>
                                    The third person is here for The Crabs.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="faq" id="faq">
                    <img src="/assets/faq.png" alt="" />
                    <div className="text-part faqq">
                        <div className="title">
                            <h3>FREQUENTLY ASKED</h3>
                            <h2>QUESTIONS</h2>
                        </div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>1.</span> What is nft?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    NFT means non-fungible tokens (NFTs), which are generally created using the same type of programming used for cryptocurrencies. <br />
                                    <br />
                                    In simple terms, these cryptographic assets are based on blockchain technology. <br />
                                    <br />
                                    They cannot be exchanged or traded equivalently like other cryptographic assets.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>2.</span> What does a blockchain do?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    A blockchain is a decentralized, distributed, and public digital ledger that is used
                                    to record transactions across many computers so that the record cannot be altered retroactively without the
                                    alteration of all subsequent blocks and the consensus of the network.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>3.</span> Is this original art ??????</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes, the Crabs has been creating art since 2022, and as a result, we can efficiently transition into a high-quality collection due to our
                                    constant work on new art pieces.                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>4.</span> Why are you minting on Ethereum??</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    ETH is the best blockchain NFT project with low frequency and high-value transactions. <br /><br />
                                    We chose to mint our tokens on Ethereum to maintain control over the quantity
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>5.</span> We use an ERC 721A contract</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    ERC721A is an improved implementation of the ERC721 standard that supports minting multiple tokens for
                                    close to the cost of one. With this implementation, users will save on
                                    gas fees over the long run if they are minting more than one token at a time.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>6.</span> Safety first?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Of course yes !! Use only the links on this page to connect your wallet and never share your private keys.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><span>7.</span> How to mint a crab?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    To mint, your Crab return to our website on the designated minting day and connect your Ethereum wallet.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <div className="discord-banner-wrap">
                    <div className="discord-banner">
                        <div className="text-part">
                            <div className="title">
                                <h3>JOIN US ON</h3>
                                <h2>DISCORD</h2>
                            </div>

                            <a href="https://discord.gg/nmeJ5NnVj2" rel="noreferrer" target="_blank">Connect with us</a>
                        </div>
                        <img src="/assets/discord.png" alt="" />
                    </div>
                </div>
                <footer>
                    <Link className='mainlogowrap' to="/"><img src="/assets/logo.png" className='mainlogo' alt="" />Copyright ©2023 The Crabs. All Rights Reserved</Link>
                    <div className=' social-icons'>
                        <a href="https://twitter.com/TheCrabsClub" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" /></a>
                        <a href="https://discord.gg/nmeJ5NnVj2" target="_blank" rel="noreferrer"><img src="/assets/discord.svg" alt="" /></a>
                    </div>
                </footer>
                <a style={{ color: '#000', fontSize: 12, textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', marginInline: 'auto' }} href="https://raritysniper.com/nft-drops-calendar" target="_blank" rel="noreferrer">NFT Drops</a>s
            </div>
        </>
    )
}
export default Home;